import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from './TextInput';

const Form = ({ open, closePopup, selectedCard }) => {
  const [formData, setFormData] = useState({ name: '', phone: '', address: '', cardOption: selectedCard });

  useEffect(() => {
    if (selectedCard) {
      setFormData((prevFormData) => ({ ...prevFormData, cardOption: selectedCard }));
    }
  }, [selectedCard]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, address, cardOption } = formData;

    if (!name || !phone || !address) {
      alert('Please fill in all required fields.');
      return;
    }

    const scriptURL = 'https://script.google.com/macros/s/AKfycbwmRjwGwnfvqLOZPK_Lpcw-3pltuh4eD2LeFTp-4VkgpA85Fjmk1gJQ7LeK5PBXyT2C_w/exec';

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        name: name,
        phone: phone,
        address: address,
        cardOption: cardOption
      }),
    };

    try {
      const response = await fetch(scriptURL, requestOptions);
      if (response.ok) {
        alert('Form submission successful.');
      } else {
        console.error('Server responded with an error:', response.status, response.statusText);
        alert('Form submission failed.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Form submission failed.');
    }
  };

  return (
    <Dialog open={open} onClose={closePopup} fullWidth maxWidth="md"
    PaperProps={{ 
      sx: { 
        borderRadius: '50px' 
      } 
    }}
    >
      <DialogTitle sx={{
          textAlign: "center",
          font: "normal normal bold 4rem/100px Almarai",
          position: 'relative',
          '@media (max-width: 600px)': {
            fontSize: '2rem',
          },
        }}>
        اطلب الآن
        <hr className="line" />
        <IconButton
          onClick={closePopup}
          style={{ position: 'absolute', right: 20, top: 20, zIndex: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        <Stack spacing={2} margin={2} style={{ gap: "5px" }}>
          <TextInput type="text" name="name" value={formData.name} onChange={handleChange} placeholder="الاسم" required />
          <TextInput type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="رقم الهاتف" required />
          <TextInput type="text" name="address" value={formData.address} onChange={handleChange} placeholder="عنوان" required />
          <TextField
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '20px',
              backgroundColor: "transparent",
              border: "7px solid #19c910",
              borderRadius: "131px",
              fontFamily: "Almarai",
              fontSize: "25px",
              letterSpacing: "0px",
              color: "#001B0B",
              paddingRight: "10px",
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
              '@media (max-width: 600px)': {
                fontSize: '20px',
                padding: '5px',
              },
            }}
            type="text"
            name="cardOption"
            value={formData.cardOption}
            readOnly
          />
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button 
            onClick={handleSubmit} 
            sx={{
              background: 'linear-gradient(45deg, #FFFFFF 30%, #FFFFFF 90%)',
              boxShadow: '0px 3px 2px 2px black',
              color: 'black',
              borderRadius: '56px',
              fontFamily: 'Almarai',
              fontSize: '20px',
              fontWeight: 'bold',
              width: '200px',
              height: '60px',
              '&:hover': {
                background: 'linear-gradient(45deg, #19c910 30%, #19c910 90%)',
                boxShadow: '0px 6px 10px 4px black',
              },
              '@media (max-width: 600px)': {
                width: '150px',
                height: '50px',
                fontSize: '16px',
              },
            }}
          >
            اطلب الآن
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Form;