import React from 'react';
import './textInput.css';


const TextInput = ({ type, name, value, onChange, placeholder, required }) => (
  <input
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    required={required}
    fullWidth
    variant="outlined"
    
  />
);

export default TextInput;
